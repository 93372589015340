import { maxPetAgeNumberOfYears } from "../settings/pet";
import {
  dateIsBetween,
  getDateXDaysAgo,
  getDateXWeeksAgo,
  getDateXYearsAgo,
  getIsoDate,
} from "./date";

interface AgeRangeTranslation {
  key: string;
  params: object;
}

export const getAgeRangeTranslationObject = (
  dateString: string | undefined
): AgeRangeTranslation => {
  const translation = {
    key: "",
    params: {},
  };

  if (dateString === undefined) {
    return translation;
  }

  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  let rangeKey;

  if (dateIsBetween(date, getDateXWeeksAgo(8), getDateXDaysAgo(1))) {
    rangeKey = "0_to_7_weeks";
  } else if (dateIsBetween(date, getDateXYearsAgo(1), getDateXWeeksAgo(8))) {
    rangeKey = "8_weeks_to_12_months";
  }

  if (rangeKey !== undefined) {
    translation.key = `common.pet_age.ranges.${rangeKey}.label`;

    return translation;
  }

  for (let years = 1; years < 11; years++) {
    if (dateIsBetween(date, getDateXYearsAgo(years + 1), getDateXYearsAgo(years))) {
      translation.key = `common.pet_age.years.label`;
      translation.params = { count: years };

      return translation;
    }
  }

  translation.key = `common.pet_age.ranges.11_years_or_more.label`;

  return translation;
};

export const getMonthOptions = (): { value: string; label: string }[] => {
  return Array.from({ length: 12 }, (_, index) => ({
    value: index.toString(),
    label: `common.month.${index}.label`,
  }));
};

export const getYearsAvailable = (): string[] => {
  const currentYear = new Date().getFullYear();

  return Array.from({ length: maxPetAgeNumberOfYears + 1 }, (_, index) =>
    (currentYear - index).toString()
  );
};

export const calcPetBirthDate = (month: string, year: string): string => {
  const petBirthDate: Date = new Date();

  petBirthDate.setFullYear(parseInt(year, 10), parseInt(month, 10), 1);

  return getIsoDate(petBirthDate);
};
