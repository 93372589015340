import { getDateXMonthsAgo } from "../utils/date";

export const minPetAgeNumberOfWeeks = 8;
export const minPetAgeNumberOfMonths = 2;
export const maxPetAgeNumberOfYears = 11;
export const fakeChipNumber = "123123999123123";

export enum PetSpecies {
  cat = "cat",
  dog = "dog",
}

export enum PetSex {
  female = "female",
  male = "male",
}

export enum Disease {
  AtopyOrAllergicSkinDisease = "atopy-or-allergic-skin-disease",
  CruciateLigamentRuptureOrDisease = "cruciate-ligament-rupture-or-disease",
  LuxatingPatella = "luxating-patella",
  DysplasiaOfTheElbowOrHip = "dysplasia-of-the-elbow-or-hip",
  EpilepsyOrSeizures = "epilepsy-or-seizures",
  Osteoarthritis = "osteoarthritis",
  HerniatedDisk = "herniated-disk",
}

export const isPetYoungerThanMinAge = (petBirthDateString: string): boolean => {
  const petBirthDate = new Date(petBirthDateString);
  const monthsAgo = getDateXMonthsAgo(minPetAgeNumberOfMonths, new Date());

  return petBirthDate > monthsAgo;
};
