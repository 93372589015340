import { CalendarDate } from "@internationalized/date";

export const getDateXDaysAgo = (numOfDays: number, date: Date = new Date()): Date => {
  const daysAgo = new Date();

  daysAgo.setDate(date.getDate() - numOfDays);
  daysAgo.setHours(0, 0, 0, 0);

  return daysAgo;
};

export const getDateXWeeksAgo = (numOfWeeks: number, date: Date = new Date()): Date => {
  const daysAgo = new Date();

  daysAgo.setDate(date.getDate() - numOfWeeks * 7);
  daysAgo.setHours(0, 0, 0, 0);

  return daysAgo;
};

export const getDateXMonthsAgo = (numOfMonths: number, date: Date = new Date()): Date => {
  const monthsAgo = new Date(date);
  monthsAgo.setMonth(date.getMonth() - numOfMonths);
  monthsAgo.setHours(0, 0, 0, 0);

  return monthsAgo;
};

export const getDateXYearsAgo = (numOfYears: number, date: Date = new Date()): Date => {
  const daysAgo = new Date();

  daysAgo.setFullYear(date.getFullYear() - numOfYears);
  daysAgo.setHours(0, 0, 0, 0);

  return daysAgo;
};

export const getIsoDate = (date: Date): string => new Intl.DateTimeFormat("sv-SE").format(date);

export const dateIsBetween = (date: Date, minDate: Date, maxDate: Date): boolean =>
  date > minDate && date <= maxDate;

export const dateIsBefore = (date: Date, thresholdDate: Date): boolean => date < thresholdDate;

export const dateIsAfter = (date: Date, thresholdDate: Date): boolean => date > thresholdDate;

export const dateIsEqual = (date: Date, thresholdDate: Date): boolean =>
  date.getTime() === thresholdDate.getTime();

export const formatDate = (dateString: string, locale: string): string => {
  const date: Date = new Date(dateString);

  return new Intl.DateTimeFormat(locale).format(date);
};

export function parseToCalendarDate(dateString?: string): CalendarDate | undefined {
  if (!dateString) {
    return undefined;
  }
  const [year, month, day] = dateString.split("-").map(Number);

  return new CalendarDate(year, month, day);
}
